import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    props: true,
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/orders/:pid',
    name: 'Orders',
    props: true,
    component: () => import('../views/Navigation.vue'),
    children: [
      {
        path: '/orders/:pid/payment_failed',
        name: 'InvoicePaymentFailed',
        props: true,
        component: () => import('../views/PaymentFailed.vue')
      },
      {
        path: '/orders/:pid/online_payment',
        name: 'InvoiceOnlinePayment',
        props: true,
        component: () => import('../views/OnlinePayment.vue')
      },
      {
        path: '/orders/:pid/bank_transfer',
        name: 'InvoiceBankTransfer',
        props: true,
        component: () => import('../views/BankTransfer.vue')
      }
    ]
  },
  {
    path: '/checkout/:pid',
    name: 'Checkout',
    props: true,
    component: () => import('../views/Navigation.vue'),
    children: [
      {
        path: '/checkout/:pid/bank_transfer',
        name: 'BankTransfer',
        props: true,
        component: () => import('../views/BankTransfer.vue')
      }
    ]
  },
  {
    path: '/tmp_checkout/:binKey',
    name: 'TmpCheckout',
    props: true,
    component: () => import('../views/TmpCheckout.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
