const finalProducts = function (state, neglectEmpty) {
  const finalProducts = []
  state.tmpCart.forEach(item => {
    const selectedItem = {
      id: item.id.toString().includes('tmp_') ? null : item.id,
      product: item.product.id,
      quantity: item.quantity,
    }
    if (item.addons) {
      selectedItem.addons = item.addons.map(m => { return {
        id: m.id || null,
        product: m.product.id,
        quantity_per_set: m.quantity_per_set || 0,
      }}).filter(f => (!f.id && f.quantity_per_set > 0) || f.id)
    }
    if (item.bundles) {
      selectedItem.bundles = item.bundles.map(m => { 
        let bundle = {
          id: m.id || null,
          product: m.product.id,
          quantity_per_set: 1,
        }
        if (m.product.product_notes_data) {
          bundle.product_notes_data = JSON.parse(JSON.stringify(m.product.product_notes_data))
        }
        return bundle
      })
    }
    if (item.product_notes_data) {
      selectedItem.product_notes_data = JSON.parse(JSON.stringify(item.product_notes_data))
    }
    finalProducts.push(selectedItem)
  })
  const finalProductsData = finalProducts.filter(f => (!f.id && f.quantity > 0) || f.id)
  if (neglectEmpty) {
    let productData = []
    finalProductsData.forEach(product => {
      if (product.addons && product.addons.length > 0) {
        product.addons = product.addons.filter(f => f.quantity_per_set && f.quantity_per_set > 0)
      }
      if (product.quantity > 0) {
        productData.push(product)
      }
    })
    return productData
  }
  return finalProductsData
}

const preserveProductData = (stateCart, newCart) => {
  newCart.forEach(o => {
    const cart = stateCart.find(f => f.product.id === o.product.id)
    o.product = cart.product
  })
  return newCart
}

export default {
  namespaced: true,
  state: {
    leadPid: null,
    cart: [],
    tmpCart: [],
    tmpCheckout: false,
  },
  mutations: {
    updateCart (state, cart) {
      state.cart = cart
      state.tmpCart = [ ...state.cart ]
    },
    updateCartData (state, cart) {
      state.cart = preserveProductData(state.cart, cart)
      state.tmpCart = [ ...state.cart ]
    },
    updateLeadCart (state, data) {
      state.leadPid = data.pid
      state.cart = data.cart
      state.tmpCart = [ ...state.cart ]
    },
    syncTmpCart (state) {
      state.tmpCart = [ ...state.cart ]
    },
    addItem (state, item) {
      state.tmpCart.push(item)
    },
    updateItem (state, item) {
      if (!item.id) {
        item.id = `tmp_${new Date().getTime()}`
      }
      state.tmpCart = state.tmpCart.filter(f => f.id !== item.id)
      state.tmpCart.push(item)
    },
    deleteItem (state, item) {
      state.tmpCart = state.tmpCart.filter(f => f.timestamp !== item.timestamp)
    },
    setTmpCheckout (state, isTmp) {
      state.tmpCheckout = isTmp
    }
  },
  getters: {
    getCart (state) {
      return state.cart
    },
    getTmpCart (state) {
      return state.tmpCart
    },
    getSanitizedFinalProducts (state) {
      return finalProducts(state, true)
    },
    getFinalProducts (state) {
      return finalProducts(state, false)
    }
  }
}
