<template lang="pug">
v-row.ma-0(v-if="totalPages > 1")
  v-spacer
  v-col.text-right.pa-0
    span.c2.primary400--text {{ currentPage }} of {{ count | displayNumber }}
  v-col.shrink.pa-0
    v-btn.ml-4.rounded-l(:class="{ primary500: page !== 1, primary300: page === 1 }" tile icon height="24" width="24" @click="page--" :disabled="page === 1")
      eva-icon(style="padding-top: 4px; padding-right: 2px;" name="arrow-ios-back-outline" height="14" :fill="$vuetify.theme.themes[$theme].basic100")
  v-col.shrink.pa-0
    v-btn.ml-4.rounded-r(:class="{ primary500: page !== totalPages, primary300: page === totalPages }" tile icon height="24" width="24" @click="page++" :disabled="page === totalPages")
      eva-icon(style="padding-top: 4px; padding-right: 2px;" name="arrow-ios-forward-outline" height="14" :fill="$vuetify.theme.themes[$theme].basic100")
</template>

<script>
export default {
  name: 'YPagination',
  props: ['value', 'pageSize', 'count'],
  data: (vm) => ({
    page: vm.value || 36
  }),
  computed: {
    totalPages () {
      return Math.ceil(this.count/this.pageSize)
    },
    currentPage () {
      let fromNumber = (this.page - 1) * this.pageSize + 1
      fromNumber = this.$options.filters.displayNumber(fromNumber)
      let toNumber
      if (this.value === this.totalPages) {
        toNumber = this.count
      } else {
        toNumber = this.page * this.pageSize
      }
      toNumber = this.$options.filters.displayNumber(toNumber)
      return `${fromNumber} - ${toNumber}`
    }
  },
  watch: {
    value (val) {
      this.page = val
    },
    page (val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>