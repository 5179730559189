export default {
  namespaced: true,
  state: {
    currency: null
  },
  mutations: {
    updateCurrency (state, currency) {
      state.currency = currency
    }
  },
  getters: {
    getCurrency (state) {
      const data = {
        code: 'MYR',
        symbol: 'RM',
        multiplier: 100,
        exponent: 2
      }
      if (state.currency) {
        data.code = state.currency.currency_code
        data.multiplier = state.currency.currency_sub_unit
        data.exponent = state.currency.currency_sub_unit.toString().length - 1
        data.symbol = state.currency.currency_symbol
      }
      return data
    }
  }
}