import { DateTime } from 'luxon'
import * as filters from '../../plugins/filters'

const requiredExtraFields = function (state) {
  if (state.data.lead.form.type === 'store' && ((state.data.lead.form.extra_fields.length > 0 && state.data.lead.form.extra_fields.map(m => m.enabled).includes(true)) || (state.data.lead.form.email_enabled && (state.data.lead.form.email_required)))) {
    return true
  }
  return false
}

const isCourierPlus = function (state) {
  let containCourier = false
  let containLocalDelivery = false
  const zones = state.data.zones
  if (zones.length > 0) {
    if (zones.filter(f => f.fulfilment_type.includes("courier")).length > 0) {
      containCourier = true
    }
    if (zones.filter(f => f.fulfilment_type.includes("local_delivery")).length > 0) {
      containLocalDelivery = true
    }
  }
  return containCourier && containLocalDelivery
}

const isCanAsap = function (state, type) {
  return state.data.oms.business_hour.filter(f => f.type_name === type)[0].can_ASAP
}

const stillOpenToday = function (state, type) {
  let hasToday = false
  let hours = state.data.oms.business_hour.filter(f => f.type_name === type)
  hours.forEach((o) => {
    if (o.days.filter(f => f.day === 'Today').length > 0) {
      hasToday = true
    }
  })
  return hasToday
}

const isCanSchedule = function (state, stillOpenToday) {
  if (state.data.oms.scheduling_enabled && !stillOpenToday && state.data.oms.scheduling_begin_time_unit === 'days' && state.data.oms.scheduling_begin_time_value === 0) {
    return false
  }
  return state.data.oms.scheduling_enabled
}

const omsDatetimedata = function (state, type) {
  let available = true
  let nextbusinessHour = null
  let delivery_description = ''
  let pickup_description = ''
  let canAsap = true
  let canSchedule = false
  if (state.data.oms && state.data.oms.enabled) {
    available = false
    canAsap = isCanAsap(state, type)
    const isOpenToday = stillOpenToday(state, type)
    canSchedule = isCanSchedule (state, isOpenToday)
    for (let index = 1; index <= 7; index++) {
      let date = new Date(new Date().getTime() + (index * 24 * 60 * 60 * 1000))
      if (isOpenToday) {
        date = new Date(new Date().getTime())
      }
      if (state.data.oms.business_hour[0] && state.data.oms.business_hour[0].days.filter(f => f.day.substr(0, 3) === date.toString().substr(0, 3)).length > 0) {
        nextbusinessHour = date.toString().substr(4, 6) + ', ' + filters.covert24To12(state.data.oms.business_hour[0].days.filter(f => f.day.substr(0, 3) === date.toString().substr(0, 3))[0].hours[0].begin)
        break
      } else if (state.data.oms.business_hour[0] && state.data.oms.business_hour[1].days.filter(f => f.day.substr(0, 3) === date.toString().substr(0, 3)).length > 0) {
        nextbusinessHour = date.toString().substr(4, 6) + ', ' + filters.covert24To12(state.data.oms.business_hour[1].days.filter(f => f.day.substr(0, 3) === date.toString().substr(0, 3))[0].hours[0].begin)
        break
      }
    }
    if (canAsap && canSchedule) {
      delivery_description = 'Select date and time'
      available = true
    } else if (canAsap) {
      if (state.data.oms.preparation_time_enabled) {
        delivery_description = `Approx. ${state.data.oms.preparation_time_value} ${state.data.oms.preparation_time_unit} prep time`
        pickup_description = `Please expect approx. ${state.data.oms.preparation_time_value} ${state.data.oms.preparation_time_unit} preparation time`
      }
      available = true
    } else if (canSchedule) {
      delivery_description = 'Schedule for later'
      pickup_description = nextbusinessHour ? `This location is closed for now and will reopen on ${nextbusinessHour}. You can still schedule for later.` : 'This location is closed for now. You can still schedule for later.'
      available = true
    } else {
      if (nextbusinessHour) {
        delivery_description = 'Available on ' + nextbusinessHour
        pickup_description = `This location is closed for now and will reopen on ${nextbusinessHour}.`
      } else {
        delivery_description = 'Currently not available'
        pickup_description = 'This location is closed for now'
      }
    }
  }
  return {
    available: available,
    description: type === 'delivery' ? delivery_description : pickup_description,
    canAsap: canAsap,
    canSchedule: canSchedule
  }
}

const shopIsClosedNow = function (state) {
  const timeNow = DateTime.local().toFormat('y-LL-dd') + 'T' + DateTime.local().toFormat('HH:mm:ss') + DateTime.local().toFormat('ZZ')
  const dayToday = new Date().toString().substring(0, 3)
  if (state.data.oms && state.data.oms.business_hour && state.data.oms.business_hour.length > 0) {
    let isClose = true
    state.data.oms.business_hour.forEach((o) => {
      let today = o.days.find(f => f.day === 'Today')
      if (today) {
        today = o.days.find(f => f.day.substr(0, 3) === dayToday)
        today.hours.forEach((o) => {
          const begin = DateTime.local().toFormat('y-LL-dd') + 'T' + o.begin + DateTime.local().toFormat('ZZ')
          const end = DateTime.local().toFormat('y-LL-dd') + 'T' + o.end + DateTime.local().toFormat('ZZ')
          if (Date.parse(timeNow) >= Date.parse(begin) && Date.parse(timeNow) < Date.parse(end)) {
            isClose = false
          }
        })
      }
    })
    return isClose
  }
  return false
}

export default {
  namespaced: true,
  state: {
    data: {
      flowState: null,
      editingSection: null,
      flows: {
        FILL_IN_TMP_PHONE_FLOW: 'FillInTmpPhoneFlow',
        FILL_IN_PHONE_FLOW: 'FillInPhoneFlow',
        FILL_IN_TAC_FLOW: 'FillInTACFlow',
        FILL_IN_TMP_NAME_FLOW: 'FillInTmpNameFlow',
        FILL_IN_CONSENT_FLOW: 'FillInConsentFlow',
        FILL_IN_RECEIPIENT_FLOW: 'FillInReceipientFlow',
        FILL_IN_SHIPPING_METHOD_FLOW: 'FillInShippingMethodFlow',
        FILL_IN_DELIVERY_DATE_TIME: 'FillInDeliveryDateTimeFlow',
        FILL_IN_EXTRA_FIELDS_FLOW: 'FillInExtraFieldsFlow',
        FILL_IN_PAYMENT_FLOW: 'FillInPaymentFlow',
        PROCEED_PAYMENT_FLOW: 'ProceedPaymentFlow',
      },
      isReady: false,
      dataBin: null,
      lead: null,
      oms: null,
      zooData: null,
      zones: []
    },
    order: {
      leadPhone: null,
      leadName: null,
      leadPid: null,
      phoneStatus: 'uncheck',
      datetime: null,
      payment: null,
      paymentValidation: false,
      tempShipping: null,
      price: {
        ready: false,
        total: 0,
        amountDue: 0,
        minPaymentAmount: 0,
        paidAmount: 0
      },
      remarks: {
        valid: true,
        shipping_method: null,
        payment_method: null,
        order_message: null,
        thankyou_message: null,
        checkout_steps: []
      },
    }
  },
  mutations: {
    // order
    recoverOrder (state, lead) {
      const orderString =  localStorage.getItem('yzc_order_data')
      if (orderString) {
        const order = JSON.parse(orderString)
        if (order.leadPhone !== lead.phone || order.leadPid !== lead.pid) {
          localStorage.removeItem('yzc_order_data')
          return
        }
        state.order = order
      }
    },
    updateLeadSignature (state, lead) {
      state.order.leadPhone = lead.phone
      state.order.leadPid = lead.pid
      localStorage.setItem('yzc_order_data', JSON.stringify(state.order))
    },
    updateOrderLeadPhone (state, phone) {
      state.order.leadPhone = phone
      localStorage.setItem('yzc_order_data', JSON.stringify(state.order))
    },
    updateOrderLeadName (state, name) {
      state.order.leadName = name
      localStorage.setItem('yzc_order_data', JSON.stringify(state.order))
    },
    updatePhoneStatus (state, status) {
      state.order.phoneStatus = status
      localStorage.setItem('yzc_order_data', JSON.stringify(state.order))
    },
    updateOrderMessage (state, message) {
      state.order.remarks.order_message = message
      localStorage.setItem('yzc_order_data', JSON.stringify(state.order))
    },
    setOrderRemarks (state, remarks) {
      if (remarks.valid) {
        state.order.remarks = remarks
        localStorage.setItem('yzc_order_data', JSON.stringify(state.order))
      }
    },
    updateDatetime (state, datetime) {
      state.order.datetime = datetime
      localStorage.setItem('yzc_order_data', JSON.stringify(state.order))
    },
    updatePayment (state, payment) {
      state.order.payment = payment
      state.order.remarks.payment_method = payment.remarks
      localStorage.setItem('yzc_order_data', JSON.stringify(state.order))
    },
    updateCheckoutThankyouMessage (state, message) {
      state.order.remarks.thankyou_message = message
      localStorage.setItem('yzc_order_data', JSON.stringify(state.order))
    },
    updateRemarksShipping (state, shipping) {
      const data = {
        fee: shipping.fee,
        title: shipping.title,
        shipping_method: shipping.shipping_method,
        meta_data: null,
      }
      if (shipping.meta_data && shipping.meta_data.pickup) {
        const meta_data = {
          type: shipping.meta_data.type,
          instruction: shipping.meta_data.instruction
        }
        data.meta_data = meta_data
      }
      state.order.remarks.shipping_method = data
      localStorage.setItem('yzc_order_data', JSON.stringify(state.order))
    },
    updateTempShipping (state, shipping) {
      let data = null
      if (shipping) {
        data = { ...shipping }
      }
      state.order.tempShipping = data
      localStorage.setItem('yzc_order_data', JSON.stringify(state.order))
    },
    updateOrderPrice (state, price) {
      state.order.price = price
    },

    // data
    updateDataIsReady (state) {
      state.data.isReady = true
    },
    updateFlowState(state, flowState) {
      state.data.flowState = flowState
      let steps = state.order.remarks.checkout_steps
      if (!steps) {
        steps = []
      }
      steps.push({
        step: flowState,
        datetime: DateTime.local()
      })
      state.order.remarks.checkout_steps = steps
      localStorage.setItem('yzc_order_data', JSON.stringify(state.order))
    },
    updateEditingSection(state, section) {
      if (state.data.editingSection === section) {
        state.data.editingSection = null
      } else {
        state.data.editingSection = section
      }
    },
    updateDataBin(state, dataBin) {
      state.data.dataBin = dataBin;
    },
    updateLead(state, lead) {
      state.data.lead = lead;
    },
    setOms (state, oms) {
      state.data.oms = oms
    },
    setZooData (state, zooData) {
      state.data.zooData = zooData
    },
    updateZooDataAddresses (state, addresses) {
      state.data.zooData.addresses = addresses
    },
    setZoneData (state, zones) {
      state.data.zones = zones
    },
  },
  getters: {
    // order
    getPhoneStatus (state) {
      return state.order.phoneStatus
    },
    getLeadPhone (state) {
      return state.order.leadPhone
    },
    getLeadName (state) {
      return state.order.leadName
    },
    getOrder(state) {
      return state.order
    },
    getOrderRemarks (state) {
      return state.order.remarks
    },
    getTempShipping (state) {
      return state.order.tempShipping
    },

    // data
    getDataIsReady (state) {
      state.data.isReady
    },
    getForm(state) {
      return state.data
    },
    getEditingSection(state) {
      return state.data.editingSection
    },
    getDataBin(state) {
      return state.data.dataBin
    },
    getLead(state) {
      return state.data.lead
    },
    getOms(state) {
      return state.data.oms
    },
    getDeliveryDatetimeData(state) {
      return omsDatetimedata(state, 'delivery')
    },
    getPickupDatetimeData(state) {
      return omsDatetimedata(state, 'pickup')
    },
    getZooData (state) {
      return state.data.zooData
    },
    getZones(state) {
      return state.data.zones
    },
    getRestrictedCities (state) {
      let restrictedCities = null
      const zones = state.data.zones
      if (zones.length > 0) {
        if (zones.filter(f => f.cities === null).length === 0) {
          let cities = []
          zones.forEach(o => {
            cities = [...cities, ...o.cities]
          })
          restrictedCities = cities
        }
      }
      return restrictedCities
    },
    getIsCourierPlus (state) {
      return isCourierPlus(state)
    },
    getShopIsClosedNow (state) {
      return shopIsClosedNow(state)
    },
    getRequiredExtraFields (state) {
      return requiredExtraFields(state)
    },
    getHasEmptyExtraFields (state) {
      if (state.data.lead.form.type === 'store' && ((state.data.lead.form.extra_fields.length > 0 && state.data.lead.form.extra_fields.map(m => m.enabled).includes(true) && !state.data.lead.extra_fields_data) || (state.data.lead.form.email_enabled && (state.data.lead.form.email_required && !state.data.lead.email)))) {
        return true
      }
      return false
    },
    getDateTime (state) {
      if (!state.order.datetime) {
        return {}
      }
      const datetime = { ...state.order.datetime }
      if (datetime.is_ASAP) {
        datetime.asking_delivery_date = DateTime.local().toFormat('yyyy-MM-dd')
        datetime.asking_delivery_time = DateTime.local().toFormat('HH:mm:ss')
      }
      if (!state.data.lead) {
        return datetime
      }
      if (state.data.flowState === state.data.flows.PROCEED_PAYMENT_FLOW) {
        datetime.asking_order_type = state.data.lead.pickup ? 'pickup' : 'delivery'
      } else if (!datetime.asking_order_type) {
        datetime.asking_order_type = 'delivery'
      }
      return datetime
    },
    getPayment (state) {
      return state.order.payment
    },
    getStories(state) {
      // Build stories based on current.js
      const stories = []
      if (state.data.dataBin) {
        stories.push({flow: state.data.flows.FILL_IN_TMP_PHONE_FLOW})
      }
      if (state.data.lead) {
        stories.push({flow: state.data.flows.FILL_IN_PHONE_FLOW})
      }
      if (state.order.phoneStatus === 'sendcode') {
        stories.push({flow: state.data.flows.FILL_IN_TAC_FLOW})
      }
      if (state.order.phoneStatus === 'leadname') {
        stories.push({flow: state.data.flows.FILL_IN_TMP_NAME_FLOW})
      }
      if (state.order.phoneStatus === 'consent') {
        stories.push({flow: state.data.flows.FILL_IN_CONSENT_FLOW})
      }
      if (state.data.lead && state.order.phoneStatus === 'verified') {
        if (state.data.isReady) {
          if (state.data.zones.length > 0) {
            stories.push({flow: state.data.flows.FILL_IN_RECEIPIENT_FLOW})
            stories.push({flow: state.data.flows.FILL_IN_SHIPPING_METHOD_FLOW})
            if (state.data.oms && state.data.oms.enabled && isCourierPlus(state)) {
              if (state.data.lead.pickup) {
                stories.push({flow: state.data.flows.FILL_IN_DELIVERY_DATE_TIME})
              } else if (state.data.lead.shipping && state.data.lead.shipping.fulfilment_type === 'local_delivery') {
                stories.push({flow: state.data.flows.FILL_IN_DELIVERY_DATE_TIME})
              }
            }
          }
          stories.push({flow: state.data.flows.FILL_IN_PAYMENT_FLOW})
          if (requiredExtraFields(state)) {
            stories.push({flow: state.data.flows.FILL_IN_EXTRA_FIELDS_FLOW})
          }
        }
        stories.push({flow: state.data.flows.PROCEED_PAYMENT_FLOW})
      }
      stories.forEach((story, index) => {
        stories[index].flow = story.flow
        stories[index].previous = null
        stories[index].next = null
        if (index != 0) {
          stories[index].previous = stories[index - 1].flow
        }
        if (stories.length > index + 1) {
          stories[index].next = stories[index + 1].flow
        }
      })
      return stories;
    },
  },
};
