export default {
  account_details: "Account Details",
  add_another: "Add another",
  add_coupon_code: "Add coupon code",
  add_item: "Add Item",
  add_items: "Add items",
  add_new_address: "Add new address",
  add_ons: "Add Ons",
  add_shipping_address: "Add shipping address",
  additional: "Additional",
  additional_info: "Additional info",
  address_1: "Address 1",
  address_2: "Address 2",
  amount: "Amount",
  amount_paid: "Amount Paid",
  apply: "Apply",
  approx: "approx",
  asap: "ASAP",
  back_to_checkout: "Back to checkout",
  balance_due: "Balance due",
  bank: "Bank",
  billing_address_same_as: "Billing address same as shipping",
  buy_again: "Buy again",
  by_clicking_pay_now_you_agree: "By clicking Pay Now, you agree to save your information for a faster checkout experience.",
  cancel: "Cancel",
  card_details: "Card details",
  card_holder_name: "Card holder name",
  card_number: "Card number",
  card_number_exp_date_cvv: "Card Number / Exp date / CVV",
  card_payment: "Card payment",
  cash_on_delivery: "Cash on delivery",
  change: "Change",
  change_area: "Change area",
  checkout_faster: "Checkout faster",
  checkout_faster_next: "Checkout faster next time",
  city: "City",
  click_on_button_below: "Click on button below to try checking out again.",
  close: "Close",
  cod_available: "COD available",
  come_back_on: "Come back on",
  confirm_pick_up: "Confirm pick up",
  confirm_shipping_method: "Confirm shipping method",
  contact: "Contact",
  contact_seller: "Contact Seller",
  continue_checkout: "continue checkout",
  continue_shipping_method: "continue shipping method",
  continue_to_additional_info: "Continue to additional info",
  continue_to_payment: "continue to payment",
  continue_to_review: "continue to review",
  continue_to_shipping_method: "Continue to shipping method",
  copy: "Copy",
  country: "Country",
  coupon_code: "Coupon Code",
  credit_debit_card: "Credit/Debit Card",
  date: "Date",
  date_and_time: "date and time",
  delete: "Delete",
  delivery: "Delivery",
  delivery_charge: "Delivery Charge",
  delivery_date_time: "Delivery date & time",
  delivery_is_not_available: "Delivery is not available for your address",
  description: "Description",
  didnt_receive_tac_code: "Didn't receive TAC code? Proceed anyway",
  discount: "Discount",
  do_consult_with: "Do consult with {text} if you're having trouble selecting this courier",
  download_zoo_get_notifications: "Download Zoo & get real-time notifications",
  e_wallet: "E-wallet",
  edit: "Edit",
  edit_shipping_address: "Edit shipping address",
  email: "Email",
  enjoy_faster_checkout: "Enjoy faster checkout on your next purchase.",
  enter_code: "Enter code",
  enter_the_tac_code: "Enter the TAC code we sent to your number for faster checkout",
  enter_your_postcode: "Enter your postcode",
  estimated_preparation_time: "Estimated preparation time",
  free_shipping: "Free shipping",
  i_want_to_manually: "I want to manually fill-up my details",
  is_allowed_for_this_purchase: "is allowed for this purchase.",
  learn_more: "Learn more",
  leave_message_to_seller: "Leave message to seller",
  make_full_payment_delivery: "Make full payment in person upon delivery.",
  make_full_payment_store: "Make full payment at store.",
  manual_bank_transfer: "Manual bank transfer",
  manual_transfer: "Manual Transfer",
  minimum_deposit_of: "Minimum deposit of",
  name: "Name",
  no_couriers_available: "No couriers available for your delivery address",
  not_enough_stock_available: "Not enough stock available. Change your order quantity or consult with seller.",
  now_you_can_get_notified: "Now, you can get notified when your tracking is ready",
  online_banking: "Online Banking",
  online_banking_credit_cards_ewallets: "Online Banking / Credit Cards / eWallets",
  only_count_left_in_stock: "Only {count} left in stock",
  or: "or",
  order: "Order",
  order_confirmation: "Order Confirmation",
  order_made_on: "Order made on",
  order_summary: "Order summary",
  pay_at_store: "Pay at store",
  pay_full_amount: "Pay full amount",
  pay_now: "pay now",
  pay_remaining_amount: "Pay remaining amount",
  pay_this_amount: "Pay this amount",
  payment: "Payment",
  payment_error: "Payment error",
  payment_failed: "Payment failed",
  payment_for_order_could_not: "Payment for order &#35;{text} could not process.",
  payment_method: "Payment method",
  phone_number: "Phone Number",
  pick_up: "Pick up",
  pick_up_at: "pick up at",
  pick_up_instruction: "Pick up instruction",
  pick_up_location: "Pick up location",
  please_complete_payment_before: "Please complete payment before",
  please_enter_your_name: "Please enter your name",
  please_expect_approx: "Please expect approx",
  please_select_date_and_time: "Please select date and time",
  postcode: "Postcode",
  powered_by_yezza: "Powered by Yezza",
  preferred_pick_up_area: "preferred pick up area",
  preparation_time: "preparation time",
  price: "Price",
  products_not_found: "Products not found or empty",
  promo_discount: "Promo discount",
  recipient: "Recipient",
  recipient_info: "Recipient info",
  refreshing: "Refreshing",
  remove: "Remove",
  remove_coupon: "Remove coupon",
  remove_coupon_confirmation: "Are you sure you want to remove this coupon code?",
  remove_item: "Remove item",
  report_payment_issue: "Report payment issue",
  resend: "Resend",
  returning_customer: "Returning customer",
  save: "Save",
  save_address: "Save address",
  save_card: "Save Card",
  save_date_and_time: "Save date and time",
  save_order: "Save Order",
  save_pick_up: "Save pick up",
  save_shipping_method: "save shipping method",
  save_this_information_and_checkout: "Save this information and checkout faster next time you shop on this store or other stores powered by Yezza.",
  schedule: "Schedule",
  schedule_for_later: "Schedule for later",
  search_product: "Search product",
  secure_checkout: "Secure checkout",
  select_area: "Select area",
  select_bank: "Select bank",
  select_date: "Select date",
  select_date_and_time: "Select date and time",
  select_date_and_time_for: "Select date and time for",
  select_payment_method: "Select payment method",
  select_provider: "Select provider",
  select_quantity: "Select quantity",
  select_shipping_address: "Select shipping address",
  select_shipping_method: "Select shipping method",
  select_time: "Select time",
  select_your_delivery_area: "Select your delivery area",
  self_pickup: "Self Pickup",
  send_proof_of_payment: "Send proof of payment",
  service_fee: "Service Fee",
  shipping: "Shipping",
  shipping_address: "Shipping address",
  shipping_method: "Shipping method",
  show_less: "Show less",
  show_more: "Show more",
  sold_out: "Sold out",
  state: "State",
  subtotal: "Subtotal",
  switch_to_another_payment_method: "Switch to another payment method",
  switch_to_delivery: "Switch to delivery",
  switch_to_pick_up: "Switch to pick up",
  switch_to_pickup: "Switch to pick up",
  tax: "Tax",
  the_url_no_longer_valid: "This url is no longer valid",
  this_is_a_computer_generated_invoice: "This is a computer generated invoice. No signature required.",
  this_is_a_computer_generated_receipt: "This is a computer generated receipt. No signature required.",
  time: "Time",
  total: "Total",
  total_due_at_pickup: "Total due at pickup",
  total_due_upon_delivery: "Total due upon delivery",
  transfer_after_clicking_pay: "Transfer after clicking Pay Now. We'll provide you the account details then.",
  unexpected_error_occur: "Unexpected error occur. Please contact the seller",
  update_cart: "Update cart",
  verify_again: "Verify again",
  view_in_zoo: "View in Zoo",
  view_my: "view my",
  we_noticed_you_have_previous: "We noticed you have previous purchases on Yezza. Would you like a faster checkout?",
  were_closed_for_now: "We're closed for now",
  were_closed_schedule_for_later: "We're closed for now. You can still schedule for later below.",
  were_closed_will_reopen_on: "We're closed for now and will reopen on {day}. You can still schedule for later below.",
  whatsapp_us: "WhatsApp us",
  working_days: "working day(s)",
  write_a_valid_coupon: "Write a valid coupon code here",
  yes_please_auto_fill: "YES, please auto-fill my details",
  your_cart: "Your cart",
  your_order: "Your order",
  your_pick_up_location_will: "Your pick up location will appear here once you select an area",
  zoo_account_created: "Zoo account created"
}