import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import vuetify from './plugins/vuetify';
import * as filters from './plugins/filters'
import '@yezza/ui-components/dist/ui-components.css'
import { YSchedulePicker } from '@yezza/ui-components'
import YCheckbox from '@/components/custom/YCheckbox'
import YPagination from '@/components/custom/YPagination'
import '@/styles/global.scss'
import i18n from './i18n'
import VueClipboard from 'vue-clipboard2'

Vue.config.productionTip = false
Vue.component('y-checkbox', YCheckbox)
Vue.component('y-pagination', YPagination)
Vue.component('y-schedule-picker', YSchedulePicker)
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

Vue.prototype.$theme = 'light'
Vue.prototype.$userFlows = {}

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Sentry.init({
  dsn: 'https://810a76db789d4bc5ba0a768e0bf0df4c@sentry.arbatravel.com/20',
  environment: process.env.VUE_APP_ENV,
  send_default_pii: true,
  integrations: [new Integrations.Vue({Vue, attachProps: true})],
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
