import Vue from "vue"
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import EvaIcons from 'vue-eva-icons'
import VueTablerIcons from 'vue-tabler-icons'
import TimerIcon from '@/components/icons/TimerIcon.vue'

Vuetify.config.silent = true
Vue.use(EvaIcons)
Vue.use(Vuetify)
Vue.use(VueTablerIcons)

export default new Vuetify({
  treeShake: true,
  theme: {
    dark: false,
    themes: {
      light: {
        // text color
        textColor: "#222B45",
        textColorDefault: "#222B45",
        textColorAccent: "#3366FF",
        textColorAlternative: "#FFFFFF",
        textColorHint: "#8F9BB3",
        textColorDisabled: "#8F9BB380",
        textColorZoo: '#6231DB',
        // primary
        primary: "#333333",
        primaryT08: "#33333314",
        primaryT16: "#33333329",
        primaryT24: "#3333333D",
        primaryT32: "#33333352",
        primaryT40: "#33333366",
        primaryT48: "#3333337A",
        primary100: "#F4F4F4",
        primary200: "#EAEAEA",
        primary300: "#C1C1C1",
        primary400: "#848484",
        primary500: "#333333",
        primary600: "#2B2525",
        primary700: "#24191B",
        primary800: "#1D1013",
        primary900: "#18090E",
        // basic
        basic: "#C1C1C1",
        basic100: "#FFFFFF",
        basic100T08: "#FFFFFF14",
        basic100T16: "#FFFFFF29",
        basic100T24: "#FFFFFF3D",
        basic100T32: "#FFFFFF52",
        basic100T40: "#FFFFFF66",
        basic100T48: "#FFFFFF7A",
        basic100T64: "#FFFFFFA3",
        basic200: "#F7F7F7",
        basic300: "#F4F4F4",
        basic400: "#EAEAEA",
        basic500: "#C1C1C1",
        basic600: "#848484",
        basic600T08: "#8F9BB314",
        basic600T16: "#8F9BB329",
        basic600T24: "#8F9BB33D",
        basic600T32: "#8F9BB352",
        basic600T40: "#8F9BB366",
        basic600T48: "#8F9BB37A",
        basic700: "#333333",
        basic800: "#2B2525",
        basic900: "#24191B",
        basic1000: "#1D1013",
        basic1100: "#18090E",
        // success
        success: "#009966",
        successT08: "#00996614",
        successT16: "#00996629",
        successT24: "#0099663D",
        successT32: "#00996652",
        successT40: "#00996666",
        successT48: "#0099667A",
        success100: "#C7F9D5",
        success200: "#92F4B7",
        success300: "#59E099",
        success400: "#30C183",
        success500: "#009966",
        success600: "#008364",
        success700: "#006E5F",
        success800: "#005855",
        success900: "#004449",
        // info
        info: "#3366FF",
        infoT08: "#3366FF14",
        infoT16: "#3366FF29",
        infoT24: "#3366FF3D",
        infoT32: "#3366FF52",
        infoT40: "#3366FF66",
        infoT48: "#3366FF7A",
        info100: "#D6E4FF",
        info200: "#ADC8FF",
        info200T08: "#ADC8FF14",
        info200T16: "#ADC8FF29",
        info200T24: "#ADC8FF3D",
        info200T32: "#ADC8FF52",
        info200T40: "#ADC8FF66",
        info200T48: "#ADC8FF7A",
        info300: "#84A9FF",
        info400: "#6690FF",
        info500: "#3366FF",
        info600: "#254EDB",
        info700: "#1939B7",
        info800: "#102693",
        info900: "#091A7A",
        // warning
        warning: "#FFCC00",
        warningT08: "#FFCC0014",
        warningT16: "#FFCC0029",
        warningT24: "#FFCC003D",
        warningT32: "#FFCC0052",
        warningT40: "#FFCC0066",
        warningT48: "#FFCC007A",
        warning100: "#FFF9CC",
        warning200: "#FFF099",
        warning300: "#FFE666",
        warning400: "#FFDC3F",
        warning500: "#FFCC00",
        warning600: "#DBAA00",
        warning700: "#B78B00",
        warning800: "#936D00",
        warning900: "#7A5700",
        // danger
        danger: "#CD3300",
        dangerT08: "#CD330014",
        dangerT16: "#CD330029",
        dangerT24: "#CD33003D",
        dangerT32: "#CD330052",
        dangerT40: "#CD330066",
        dangerT48: "#CD33007A",
        danger100: "#FCE4C9",
        danger200: "#F9C495",
        danger300: "#EF985F",
        danger400: "#E06E38",
        danger500: "#CD3300",
        danger600: "#AF1F00",
        danger700: "#920F00",
        danger800: "#760400",
        danger900: "#610003"
      }
    },
  },
  icons: {
    values: {
      'icon-timer': {
        component: TimerIcon
      },
    },
  },
})