export default {
  namespaced: true,
  state: {
    lang: 'en'
  },
  mutations: {
    recoverLanguage (state) {
      const language = localStorage.getItem('yz_language')
      if (language) {
        state.lang = language
      } else {
        localStorage.setItem('yz_language', state.lang)
      }
    },
    setLanguage (state, language) {
      state.lang = language
      localStorage.setItem('yz_language', state.lang)
    }
  },
  getters: {
    getLanguage (state) {
      return state.lang
    }
  }
}
