export default {
  account_details: "Detail Akun",
  add_another: "Tambahkan yang lain",
  add_coupon_code: "Tambahkan kode kupon",
  add_item: "Tambahkan Barang",
  add_items: "Tambahkan item",
  add_new_address: "Tambahkan alamat baru",
  add_ons: "Add On",
  add_shipping_address: "Tambahkan alamat pengiriman",
  additional: "Tambahan",
  additional_info: "Info tambahan",
  address_1: "Alamat 1",
  address_2: "Alamat 2",
  amount: "Jumlah",
  amount_paid: "Jumlah yang Dibayar",
  apply: "Terapkan",
  approx: "perkiraan",
  asap: "secepatnya",
  back_to_checkout: "Kembali ke pembayaran",
  balance_due: "Saldo jatuh tempo",
  bank: "Bank",
  billing_address_same_as: "Alamat penagihan sama dengan pengiriman",
  buy_again: "Beli lagi",
  by_clicking_pay_now_you_agree: "Dengan mengklik Bayar Sekarang, Anda setuju untuk menyimpan informasi Anda untuk pengalaman checkout yang lebih cepat.",
  cancel: "Batal",
  card_details: "Detail kartu",
  card_holder_name: "Nama pemegang kartu",
  card_number: "Nomor kartu",
  card_number_exp_date_cvv: "Nomor Kartu / Tanggal Kedaluwarsa / CVV",
  card_payment: "Pembayaran kartu",
  cash_on_delivery: "Cash on delivery",
  change: "Ubah",
  change_area: "Ubah area",
  checkout_faster: "Checkout lebih cepat",
  checkout_faster_next: "Lain kali checkout lebih cepat",
  city: "Kota",
  click_on_button_below: "Klik tombol di bawah untuk mencoba check out lagi.",
  close: "Tutup",
  cod_available: "COD tersedia",
  come_back_on: "Ayo kembali",
  confirm_pick_up: "Konfirmasi pengambilan",
  confirm_shipping_method: "Konfirmasi metode pengiriman",
  contact: "Kontak",
  contact_seller: "Hubungi Penjual",
  continue_checkout: "lanjutkan checkout",
  continue_shipping_method: "melanjutkan metode pengiriman",
  continue_to_additional_info: "Lanjutkan ke info tambahan",
  continue_to_payment: "lanjutkan pembayaran",
  continue_to_review: "lanjutkan tinjauan",
  continue_to_shipping_method: "Lanjutkan ke metode pengiriman",
  copy: "Salin",
  country: "Negara",
  coupon_code: "Kode Kupon",
  credit_debit_card: "Kartu Kredit/Debit",
  date: "Tanggal",
  date_and_time: "tanggal dan waktu",
  delete: "Hapus",
  delivery: "Pengiriman",
  delivery_charge: "Biaya Pengiriman",
  delivery_date_time: "Tanggal & waktu pengiriman",
  delivery_is_not_available: "Pengiriman tidak tersedia untuk alamat Anda",
  deskripsi: "Deskripsi",
  didnt_receive_tac_code: "Tidak menerima kode TAC? Tetap lanjutkan",
  discount: "Diskon",
  do_consult_with: "Berkonsultasilah dengan {text} jika Anda kesulitan memilih kurir ini",
  download_zoo_get_notifications: "Unduh Zoo & dapatkan pemberitahuan waktu nyata",
  e_wallet: "Dompet elektronik",
  edit: "Edit",
  edit_shipping_address: "Edit alamat pengiriman",
  email: "Email",
  enjoy_faster_checkout: "Nikmati checkout lebih cepat untuk pembelian berikutnya.",
  enter_code: "Masukkan kode",
  enter_the_tac_code: "Masukkan kode TAC yang kami kirimkan ke nomor Anda untuk checkout lebih cepat",
  enter_your_postcode: "Masukkan kode pos Anda",
  estimated_preparation_time: "Perkiraan waktu persiapan",
  free_shipping: "Gratis ongkos kirim",
  i_want_to_manually: "Saya ingin mengisi data saya secara manual",
  is_allowed_for_this_purchase: "diizinkan untuk pembelian ini.",
  learn_more: "Pelajari lebih lanjut",
  leave_message_to_seller: "Tinggalkan pesan ke penjual",
  make_full_payment_delivery: "Lakukan pembayaran penuh secara langsung setelah pengiriman.",
  make_full_payment_store: "Lakukan pembayaran penuh di toko.",
  manual_bank_transfer: "Transfer bank manual",
  manual_transfer: "Transfer Manual",
  minimum_deposit_of: "Setoran minimum",
  name: "Nama",
  no_couriers_available: "Tidak ada kurir yang tersedia untuk alamat pengiriman Anda",
  not_enough_stock_available: "Stok tidak mencukupi. Ubah jumlah pesanan Anda atau konsultasikan dengan penjual.",
  now_you_can_get_notified: "Sekarang, Anda dapat menerima pemberitahuan saat pelacakan Anda siap",
  online_banking: "Perbankan Online",
  online_banking_credit_cards_ewallets: "Perbankan Online / Kartu Kredit / eWallets",
  only_count_left_in_stock: "Stok tinggal {count} saja",
  or: "atau",
  order: "Pesanan",
  order_confirmation: "Konfirmasi Pesanan",
  order_made_on: "Pesanan dilakukan pada",
  order_summary: "Ringkasan pesanan",
  pay_at_store: "Bayar di toko",
  pay_full_amount: "Bayar jumlah penuh",
  pay_now: "bayar sekarang",
  pay_remaining_amount: "Bayar jumlah yang tersisa",
  pay_this_amount: "Bayar jumlah ini",
  payment: "Pembayaran",
  payment_error: "Kesalahan pembayaran",
  payment_failed: "Pembayaran gagal",
  payment_for_order_could_not: "Pembayaran pesanan &#35;{text} tidak dapat diproses.",
  payment_method: "Metode pembayaran",
  phone_number: "Nomor Telepon",
  pick_up: "Ambil",
  pick_up_at: "ambil di",
  pick_up_instruction: "Petunjuk pengambilan",
  pick_up_location: "Lokasi pengambilan",
  please_complete_payment_before: "Silakan selesaikan pembayaran sebelum",
  please_enter_your_name: "Silakan masukkan nama Anda",
  please_expect_approx: "Harap harapkan kira-kira",
  please_select_date_and_time: "Silakan pilih tanggal dan waktu",
  postcode: "Kode pos",
  powered_by_yezza: "Diberdayakan oleh Yezza",
  prefer_pick_up_area: "area penjemputan pilihan",
  preparation_time: "waktu persiapan",
  price: "Harga",
  products_not_found: "Produk tidak ditemukan atau kosong",
  promo_discount: "Promo diskon",
  recepient: "Penerima",
  recepient_info: "Info penerima",
  refreshing: "Refreshing",
  remove: "Hapus",
  remove_coupon: "Hapus kupon",
  remove_coupon_confirmation: "Apakah Anda yakin ingin menghapus kode kupon ini?",
  remove_item: "Hapus item",
  report_payment_issue: "Laporkan masalah pembayaran",
  resend: "Kirim ulang",
  return_customer: "Pelanggan yang kembali",
  save: "Simpan",
  save_address: "Simpan alamat",
  save_card: "Simpan Kartu",
  save_date_and_time: "Simpan tanggal dan waktu",
  save_order: "Simpan Pesanan",
  save_pick_up: "Simpan pengambilan",
  save_shipping_method: "simpan metode pengiriman",
  save_this_information_and_checkout: "Simpan informasi ini dan checkout lebih cepat saat Anda berbelanja di toko ini atau toko lain yang didukung oleh Yezza.",
  schedule : "jadwal",
  schedule_for_later: "Jadwalkan nanti",
  search_product: "Telusuri produk",
  secure_checkout: "Pembayaran aman",
  select_area: "Pilih area",
  select_bank: "Pilih bank",
  select_date: "Pilih tanggal",
  select_date_and_time: "Pilih tanggal dan waktu",
  select_date_and_time_for: "Pilih tanggal dan waktu untuk",
  select_payment_method: "Pilih metode pembayaran",
  select_provider: "Pilih penyedia",
  select_quantity: "Pilih jumlah",
  select_shipping_address: "Pilih alamat pengiriman",
  select_shipping_method: "Pilih metode pengiriman",
  select_time: "Pilih waktu",
  select_your_delivery_area: "Pilih area pengiriman Anda",
  self_pickup: "Pengambilan Sendiri",
  send_proof_of_payment: "Kirim bukti pembayaran",
  service_fee: "Biaya Layanan",
  shipping: "Pengiriman",
  shipping_address: "Alamat pengiriman",
  shipping_method: "Metode pengiriman",
  show_less: "Tampilkan lebih sedikit",
  show_more: "Tampilkan lebih banyak",
  sold_out: "Terjual habis",
  state: "Negara",
  subtotal: "Subtotal",
  switch_to_another_payment_method: "Beralih ke metode pembayaran lain",
  switch_to_delivery: "Beralih ke pengiriman",
  switch_to_pick_up: "Beralih untuk mengambil",
  switch_to_pickup: "Beralih untuk mengambil",
  tax: "Pajak",
  the_url_no_longer_valid: "url ini sudah tidak valid",
  this_is_a_computer_generated_invoice: "Ini adalah faktur yang dibuat oleh komputer. Tidak perlu tanda tangan.",
  this_is_a_computer_generated_receipt: "Ini adalah tanda terima yang dibuat oleh komputer. Tidak perlu tanda tangan.",
  time: "Waktu",
  total: "Jumlah",
  total_due_at_pickup: "Total jatuh tempo saat pengambilan",
  total_due_upon_delivery: "Total jatuh tempo pada saat pengiriman",
  transfer_after_clicking_pay: "Transfer setelah mengklik Bayar Sekarang. Kami akan memberikan detail akun kepada Anda.",
  unexpected_error_occur: "Terjadi kesalahan tak terduga. Harap hubungi penjual",
  update_cart: "Perbarui keranjang",
  verify_again: "Verifikasi lagi",
  view_in_zoo: "Lihat di Zoo",
  view_my: "lihat saya",
  we_noticed_you_have_previous: "Kami melihat Anda memiliki pembelian sebelumnya di Yezza. Apakah Anda ingin checkout lebih cepat?",
  were_closed_for_now: "Kami tutup untuk saat ini",
  were_closed_schedule_for_later: "Kami tutup untuk saat ini. Anda masih dapat menjadwalkannya nanti di bawah.",
  were_closed_will_reopen_on: "Kami tutup untuk saat ini dan akan dibuka kembali pada {day}. Anda masih dapat menjadwalkannya nanti di bawah.",
  whatsapp_us: "WhatsApp kami",
  working_days: "hari kerja",
  write_a_valid_coupon: "Tulis kode kupon yang valid di sini",
  yes_please_auto_fill: "YA, silakan isi data saya secara otomatis",
  your_cart: "Keranjang Anda",
  your_order: "Pesanan Anda",
  your_pick_up_location_will: "Lokasi penjemputan Anda akan muncul di sini setelah Anda memilih area",
  zoo_account_created: "Akun Zoo telah dibuat"
}