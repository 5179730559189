export default {
  namespaced: true,
  state: {
    user: null,
    zooFetching: false,
    zooProfile: null,
    zooAvailable: false
  },
  mutations: {
    setUser (state, user) {
      state.user = user
      if (user) {
        localStorage.setItem('yzc_user', JSON.stringify(state.user))
      } else {
        localStorage.removeItem('yzc_user')
      }
    },
    setDemoUser (state) {
      let user = {
        id: 182195,
        email: '',
        auth_phone: '601777777777',
        first_name: 'Demo',
        last_name: '',
        token: 'a0c4ca9ec898cbeaa90186cc84e4d87a45d363d9',
        agent_affiliate_id: null,
        avatar_code: null,
        email_verified: false,
        whatsapp_optin: false,
        profile: 182187
      }
      if (process.env.VUE_APP_ENV === 'test') {
        user = {
          id: 897,
          email: '',
          auth_phone: '601777777777',
          first_name: 'Demo',
          last_name: '',
          token: 'f91514476165cfba9aabaca27318b62d60adcb2a',
          agent_affiliate_id: null,
          avatar_code: null,
          email_verified: false,
          whatsapp_optin: false,
          profile: 888
        }
      }
      state.user = user
      if (user) {
        localStorage.setItem('yzc_user', JSON.stringify(state.user))
      } else {
        localStorage.removeItem('yzc_user')
      }
    },
    setZooProfile (state, zooData) {
      let data = null
      if (zooData) {
        data = { ...zooData }
      }
      state.zooProfile = data
    },
    updateFetchingZoo(state, isFetching) {
      state.zooFetching = isFetching
    },
    updateZooAvailable (state, data) {
      state.zooAvailable = data
    }
  },
  getters: {
    getUser (state) {
      return state.user
    },
    getFetching (state) {
      return state.zooFetching
    },
    getZooProfile (state) {
      return state.zooProfile
    },
    getZooAvailable (state) {
      return state.zooAvailable
    }
  }
}