import Vue from 'vue'
import Vuex from 'vuex'
import message from './modules/message'
import current from './modules/current'
import cart from './modules/cart'
import user from './modules/user'
import currency from './modules/currency'
import language from './modules/language'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: { message, current, cart, user, currency, language }
})

window.$store = store

export default store

