<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17.5" viewBox="0 0 15 17.5">
    <path id="Left" d="M12.5,10H10.833V8.333a.833.833,0,1,0-1.667,0v2.5a.833.833,0,0,0,.833.833h2.5A.833.833,0,0,0,12.5,10M10,16.458a5.625,5.625,0,1,1,5.625-5.625A5.631,5.631,0,0,1,10,16.458m.823-13.077a.488.488,0,0,0,.01-.048V2.5h.833a.833.833,0,0,0,0-1.667H8.333a.833.833,0,0,0,0,1.667h.833v.833c0,.018.008.031.01.048a7.5,7.5,0,1,0,1.647,0" transform="translate(-2.5 -0.833)" fill="currentColor" fill-rule="evenodd"/>
  </svg>
  <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Icon_Time_Filled" data-name="Icon / Time / Filled" style="isolation: isolate">
      <rect id="Box" width="24" height="24" fill="none"/>
      <g id="Ellipse_85" data-name="Ellipse 85" transform="translate(2 2)" fill="#fff" stroke="currentColor" stroke-width="2">
        <circle cx="10" cy="10" r="10" stroke="none"/>
        <circle cx="10" cy="10" r="9" fill="none"/>
      </g>
      <path id="jarum_pendek" data-name="jarum pendek" d="M0,0V4" transform="translate(12 7.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1.5"/>
      <path id="jarum_panjang" data-name="jarum panjang" d="M3.5,4,0,0" transform="translate(12 12)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1.5"/>
    </g>
  </svg> -->

</template>

<script>
  export default {
    name: 'TimerIcon',
  }
</script>
