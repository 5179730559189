<template lang="pug">
.v-input
  .v-input__control
    .v-input__slot(:style="inputSlotStyle")
      .v-input--selection-controls__input(style="width: 20px;  height: 20px;")
        i
          template(v-if="isActive")
            svg(v-if="disabled" width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
              rect(width="20" height="20" rx="3" fill="#8F9BB3" fill-opacity="0.48")
              path(fill-rule="evenodd" clip-rule="evenodd" d="M12.0385 7.42926C12.3926 6.96204 13.0718 6.86049 13.5555 7.20244C14.0393 7.54439 14.1444 8.20035 13.7904 8.66757L10.4312 13.1005C10.0688 13.5787 9.36974 13.6323 8.93862 13.215L6.31701 10.677C5.89362 10.2671 5.89443 9.6033 6.31884 9.19438C6.74324 8.78546 7.43052 8.78625 7.85391 9.19614L9.49539 10.7853L12.0385 7.42926Z" fill="white")
            svg(v-else width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
              rect(width="20" height="20" rx="3" fill="#333333")
              path(fill-rule="evenodd" clip-rule="evenodd" d="M12.0385 7.42926C12.3926 6.96204 13.0718 6.86049 13.5555 7.20244C14.0393 7.54439 14.1444 8.20035 13.7904 8.66757L10.4312 13.1005C10.0688 13.5787 9.36974 13.6323 8.93862 13.215L6.31701 10.677C5.89362 10.2671 5.89443 9.6033 6.31884 9.19438C6.74324 8.78546 7.43052 8.78625 7.85391 9.19614L9.49539 10.7853L12.0385 7.42926Z" fill="white")
          template(v-else)
            svg(v-if="disabled" width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
              rect(width="20" height="20" rx="3" fill="#8F9BB3" fill-opacity="0.08")
              rect(x="0.5" y="0.5" width="19" height="19" rx="2.5" stroke="#8F9BB3" stroke-opacity="0.48")
            svg(v-else width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
              rect(x="0.5" y="0.5" width="19" height="19" rx="2.5" fill="#8F9BB3" fill-opacity="0.16" stroke="#C1C1C1")
        input(:id="_uid" role="checkbox" type="checkbox" :disabled="disabled" v-model="modelValue" :value="value" :true-value="trueValue" :false-value="falseValue" @change="isActive = !isActive" @click.stop="")
      slot(name="label")
        label.v-label.theme--light(:for="_uid" style="left: 0px; right: auto; position: relative;") {{ label }}
</template>

<script>
export default {
  name: 'YCheckbox',
  model: {
    prop: 'inputValue',
    event: 'change',
  },
  props: {
    value: {
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inputValue: {
      default: null
    },
    trueValue: {
      default: true
    },
    falseValue: {
      default: false
    },
    label: {
      default: '',
      type: String
    },
    center: {
      default: false,
    },
    readonly: {
      default: false,
    }
  },
  data () {
    return {
      isActive: false
    }
  },
  computed: {
    modelValue: {
      get() {
        return this.inputValue
      },
      set(newValue) {
        this.$emit('change', newValue);
      }
    },
    inputSlotStyle () {
      let style = ''
      if (this.center === '' || this.center) {
        style += 'display: flex; justify-content: center;'
      }
      if (this.readonly === '' || this.readonly) {
        style += 'pointer-events: none;'
      }
      return style
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler () {
        if (Array.isArray(this.modelValue)) {
          this.isActive = this.modelValue.includes(this.value)
        } else {
          this.isActive = Boolean(this.modelValue)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
