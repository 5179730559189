<template lang="pug">
  v-app(:style="cssProps")
    v-main(:style="`background-color: ${$vuetify.theme.themes[$theme].infoT08}`")
      v-snackbar.elevation-0(top tile flat :timeout="snackbarProp.timeout" :color="snackbarProp.color" v-model="snackbarProp.show")
        span.s1.basic100--text {{ snackbarProp.text }}
      router-view
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
Vue.mixin({
  methods: {
    async asyncForEach (array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    }
  }
})

export default {
  name: 'App',
  data: () => ({
    snackbarProp: {
      show: false,
      color: 'success',
      text: '',
      timeout: 3000
    }
  }),
  computed: {
    ...mapState('message', ['message']),
    ...mapState('current', ['lead']),
    ...mapGetters({
      lang: 'language/getLanguage'
    }),
    cssProps () {
      const themeColors = {}
      Object.keys(this.$vuetify.theme.themes[this.$theme]).forEach((color) => {
        themeColors[`--v-${color}`] = this.$vuetify.theme.themes[this.$theme][color]
      })
      return themeColors
    }
  },
  watch: {
    message (val) {
      if (val.type === 'success') {
        this.showSnackbarSuccess(val.text)
      } else if (val.type === 'error') {
        this.showSnackbarError(val.text)
      } else if (val.type === 'info') {
        this.showSnackbarInfo(val.text)
      } else if (val.type === 'warning') {
        this.showSnackbarWarning(val.text)
      }
    },
    lead: {
      immediate: true,
      handler (val) {
        if (val && window.posthog && window.location.hostname.includes('yezza.io')) {
          window.posthog.identify(
            val.phone,
            {
              name: val.name,
              email: val.email,
            }
          )
        }
      }
    },
    lang (val) {
      this.$root.$i18n.locale = val
    }
  },
  mounted () {
    this.disablePageZoom()
    this.$store.commit('language/recoverLanguage')
  },
  methods: {
    showSnackbarError (text) {
      this.snackbarProp.text = text
      this.snackbarProp.color = 'danger'
      this.snackbarProp.show = true
    },
    showSnackbarSuccess (text) {
      this.snackbarProp.text = text
      this.snackbarProp.color = 'success'
      this.snackbarProp.show = true
    },
    showSnackbarInfo (text) {
      this.snackbarProp.text = text
      this.snackbarProp.color = 'info'
      this.snackbarProp.show = true
    },
    showSnackbarWarning (text) {
      this.snackbarProp.text = text
      this.snackbarProp.color = 'warning'
      this.snackbarProp.show = true
    },
    disablePageZoom () {
      const tag = document.createElement('meta')
      tag.setAttribute('name','viewport')
      tag.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0')
      document.head.appendChild(tag)
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-SemiBold.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Bold.otf') format('opentype');
  font-weight: 700;
}

.prevent-text-selection {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}

.clickable {
  cursor: pointer;
}
</style>
